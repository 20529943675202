import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/tailwind.css";
import copyText from "@meforma/vue-copy-to-clipboard";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faGlobeAmericas,
  faBars,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import mitt from "mitt";
// import Vuelidate from "vuelidate";

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
});

// const emitter = mitt();

library.add(faGlobeAmericas);
library.add(faBars);
library.add(faExclamationCircle);
library.add(faDiscord);

const app = createApp(App);

// app.config.globalProperties.mitt = emitter;
app.use(router);
app.use(copyText);
app.use(i18n);
app.use(store);
// app.use(Vuelidate);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
