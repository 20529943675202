<template>
  <div
    class="
      items-left
      relative
      text-left
      rounded-lg
      bg-gray-800
      px-5
      py-5
      max-w-xs
    "
  >
    <h1 class="text-2xl font-bold mb-2">
      {{ $t("ghosts." + data.name + ".name") }}
    </h1>
    <div class="absolute top-5 right-5 text-red-500 text-lg">
      <a :href="$t('ghosts.' + data.name + '.wikiLink')" target="_blank">{{
        $t("wiki")
      }}</a>
    </div>
    <div class="mb-3">
      <p
        v-for="evidence in data.evidence"
        :key="evidence"
        :class="
          foundEvidence.includes(evidence) ? 'text-green-500' : 'text-gray-300'
        "
      >
        {{ $t(evidence) }}
      </p>
    </div>
    <p class="mb-1">
      <span class="font-bold">{{ $t("strength") }} </span>
      {{ $t("ghosts." + data.name + ".strength") }}
    </p>
    <p class="mb-2">
      <span class="font-bold">{{ $t("weakness") }} </span>
      {{ $t("ghosts." + data.name + ".weakness") }}
    </p>
    <p class="text-sm text-gray-400" v-if="data.huntsAt">
      Can hunt below {{ data.huntsAt }}% average sanity
    </p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    foundEvidence: {
      type: Array,
      required: true,
    },
  },
  // computed: {
  //     doesMatchEvidence() {
  //         return !this.foundEvidence.filter(val => !this.data.evidence.includes(val)).length > 0;
  //     }
  // }
};
</script>
