<template>
  <div class="flex items-center">
    <font-awesome-icon
      class="mr-2 text-red-500"
      :icon="['fas', 'exclamation-circle']"
    />
    <p class="text-red-500 font-semibold text-sm">{{ error }}</p>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
    },
  },
};
</script>
