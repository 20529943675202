<template>
  <div class="min-h-screen px-4 text-center" v-if="isOpen">
    <span class="inline-block h-screen align-middle" aria-hidden="true" />
    <transition
      enter-active-class="duration-300 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div
        ref="target"
        class="
          inline-block
          w-full
          max-w-3xl
          p-6
          my-8
          overflow-hidden
          text-left
          align-middle
          transition-all
          transform
          bg-gray-800
          shadow-xl
          rounded-2xl
        "
      >
        <h1 class="text-2xl text-white mb-2 font-bold" v-if="title">
          {{ title }}
        </h1>
        <slot :closeModal="closeModal" :openModal="openModal" />
      </div>
    </transition>
  </div>
</template>

<script>
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";
import { useStore } from "vuex";
// import { TransitionChild } from "@headlessui/vue";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    identifier: {
      type: String,
      required: true,
    },
    allowClickOutside: {
      type: Boolean,
      default: true,
    },
  },
  // components: {
  //   TransitionChild
  // },
  methods: {
    openModal() {
      this.$store.commit("openModal", this.identifier);
    },
    closeModal() {
      this.$store.commit("closeModal", this.identifier);
    },
  },
  computed: {
    isOpen() {
      return this.$store.getters.activeModal == this.identifier;
    },
  },
  setup(props) {
    if (props.allowClickOutside) {
      const target = ref(null);
      const store = useStore();

      onClickOutside(target, () =>
        store.commit("closeModal", props.identifier)
      );

      return { target };
    }
  },
};
</script>
