<template>
  <modal title="Woohoo! 🎉" :allowClickOutside="false">
    <p class="text-white mb-6 text-md">
      Redirecting to checkout... <br />
      <br />
      This should only take a moment :)
    </p>
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";

export default {
  components: {
    Modal,
  },
};
</script>
