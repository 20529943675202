<template>
  <modal title="👋 Welcome to the new Phasmojournal!" ref="modalRef">
    <p class="text-white mb-6 text-md">
      Hey there :) <br />
      <br />
      Phasmojournal has gotten a little bit of a rework because of the new
      Phasmophobia update! <br />
      We're working to implement some new shit for ya'll to checkout :o <br />
      For the meantime, logging in and signing up is currently disabled and
      won't work. We will share some more news later in our
      <a class="font-bold text-red-500" href="https://phasmo.lotai.xyz/discord"
        >Discord</a
      >
      which you should join :) <br />
      We won't show you this message again, if you keep seeing this message, or
      if you encounter any other bugs, please join our
      <a class="font-bold text-red-500" href="https://phasmo.lotai.xyz/discord"
        >Discord</a
      >
      and let us know, we'll sort it out for you ;) <br />
      It's a lot of changes under the hood which are not fully completed, so you
      won't notice much yet, but we have plans in the works :p <br />
      We hope you enjoy Phasmojournal! Also, Phasmojournal is better with
      friends, click the
      <span class="text-red-500 font-bold">Create Room</span> button in the top
      left and invite your friends to use it together, in real-time ;) <br />
    </p>
    <text-with-badge text="- sexnine" badgeText="Creator of Phasmojournal" />
    <div class="flex justify-end">
      <Button text="Awesome!" @click="this.$refs.modalRef.closeModal()" />
    </div>
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import Button from "../components/Button.vue";
import TextWithBadge from "../components/TextWithBadge.vue";

export default {
  components: {
    Modal,
    Button,
    TextWithBadge,
  },
};
</script>
