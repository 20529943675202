<template>
  <div class="flex items-center">
    <p :class="`text-${textSize} font-semibold`">
      {{ text }}
    </p>
    <p
      :class="`text-${badgeTextSize} bg-${badgeColor} font-semibold rounded-sm px-1 ml-2`"
    >
      {{ badgeText }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    badgeText: {
      type: String,
      required: true,
    },
    badgeColor: {
      type: String,
      default: "red-500",
    },
    textSize: {
      type: String,
      default: "lg",
    },
    badgeTextSize: {
      type: String,
      default: "sm",
    },
  },
};
</script>
