<template>
  <modal title="Password reset email sent" ref="modalRef">
    <p class="text-white mb-6 text-md">
      We've sent you an email to reset your password! <br /><br />
      If you don't see the email, wait a few minutes and check your spam folder
      :) <br />
      If you still have problems, send us a message on
      <a class="font-bold text-red-500" href="https://phasmo.lotai.xyz/discord"
        >Discord</a
      >
      or shoot us an email at phasmojournal@lotai.xyz
    </p>
    <text-with-badge text="- sexnine" badgeText="Creator of Phasmojournal" />
    <div class="flex justify-end">
      <Button text="Cool" @click="this.$refs.modalRef.closeModal()" />
    </div>
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import Button from "../components/Button.vue";

export default {
  components: {
    Modal,
    Button,
  },
};
</script>
