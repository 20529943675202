<template>
  <modal title="Privacy Policy" ref="modalRef">
    <p class="text-white mb-6 text-sm">
      We do not sell your data to third parties, and do not use the data we
      collect about you in targeted advertising. <br />
      We only store nessecary information about you for our service to function.
      <br />
      We may collect anonymous statistics to improve our app and automatically
      collect information about errors to help minimize issues you may encounter
      in our app. <br />
      We use Stripe for payment, analytics, and other business services. Stripe
      collects identifying information about the devices that connect to its
      services. Stripe uses this information to operate and improve the services
      it provides to us, including for fraud detection. You can learn more about
      Stripe and read its privacy policy at https://stripe.com/privacy.
    </p>
    <div class="flex justify-end">
      <Button text="Close" @click="this.$refs.modalRef.closeModal()" />
    </div>
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import Button from "../components/Button.vue";

export default {
  components: {
    Modal,
    Button,
  },
};
</script>
