<template>
  <button @click="token">Log token</button>
  <input v-model="modalInput" placeholder="Modal ID" class="bg-gray-600 pl-2" />
  <button @click="showModalDebug">Show modal</button>
</template>

<script>
import { auth } from "../firebase.js";

export default {
  data() {
    return {
      modalInput: "",
    };
  },
  methods: {
    token() {
      auth.currentUser.getIdToken().then((token) => {
        console.log(token);
      });
    },
    showModalDebug() {
      this.$store.commit("openModal", this.modalInput);
    },
  },
};
</script>
