<template>
  <transition
    enter-active-class="duration-300 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-200 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      class="fixed inset-0 z-75 overflow-y-auto bg-black bg-opacity-50"
      v-show="show"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
// import { TransitionRoot } from "@headlessui/vue";

export default {
  computed: {
    show() {
      return this.$store.getters.isActiveModal;
    },
  },
  // components: {
  //   TransitionRoot,
  // },
};
</script>
