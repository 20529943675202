<template>
  <modal title="Forgot Password" ref="modalRef">
    <p class="text-white text-sm mb-6">
      Give us your email and if you have a Phasmojournal account, we'll send you
      an email to reset your password ;)
    </p>
    <div class="flex flex-col">
      <form-validation-error :error="error" v-show="error" class="mb-4" />
      <text-area v-model="email" placeholder="Email" class="mb-8" />
    </div>
    <div class="flex justify-between">
      <Button text="Let's send it!" @click="sendEmail" />
      <Button text="Nevermind" @click="$refs.modalRef.closeModal()" />
    </div>
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import TextArea from "../components/GenericTextArea.vue";
import Button from "../components/Button.vue";
import { auth } from "../firebase.js";
import FormValidationError from "../components/FormValidationError.vue";

export default {
  components: {
    Modal,
    Button,
    TextArea,
    FormValidationError,
  },
  data() {
    return {
      email: "",
      error: "",
    };
  },
  methods: {
    sendEmail() {
      auth
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.$store.commit("openModal", "passwordResetSent");
        })
        .catch((error) => {
          this.error = error.message;
        });
    },
  },
};
</script>
