<template>
  <button
    class="
      whitespace-nowrap
      inline-flex
      items-center
      justify-center
      px-4
      py-2
      rounded-lg
      shadow-sm
      text-base text-gray-200
      font-semibold
      cursor-pointer
      focus:border-transparent focus:outline-none
      tooltipbtn
      bg-red-500
      hover:text-white
    "
    :class="classes"
    :style="{
      '--delay': tooltipDelay,
      '--tooltipVisible': tooltip === '' ? 'hidden' : 'visible',
    }"
  >
    {{ text }}
    <span
      :v-if="tooltip"
      class="tooltip text-sm bg-opacity-60 bg-black text-center rounded-lg"
      >{{ tooltip }}</span
    >
  </button>
</template>

<style scoped>
.tooltipbtn .tooltip {
  visibility: hidden;
  padding: 5px 10px;
  width: auto;
  position: absolute;
  z-index: 5;
  transition: 0s visibility;

  top: 90%;
  left: 43.5%;
  margin-left: -60px;
}

.tooltipbtn:hover .tooltip {
  visibility: var(--tooltipVisible);
  transition-delay: var(--delay);
}
</style>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledClasses: {
      type: String,
      default: "bg-gray-500 bg-opacity-40",
    },
    tooltip: {
      type: String,
      default: "",
    },
    tooltipDelay: {
      type: String,
      default: ".5s",
    },
  },
  computed: {
    classes() {
      var res = "";
      if (this.disabled) {
        res = this.disabledClasses;
      } else {
        res = "";
      }

      return res;
    },
  },
};
</script>
