// TODO: This component should handle if the modals are visible, so the mounted
functions work properly depending on what modal is open.

<template>
  <modal-overlay>
    <account-modal identifier="account" />
    <before-buying-modal identifier="beforeBuying" />
    <login-modal identifier="login" />
    <privacy-policy-modal identifier="privacy" />
    <redirecting-to-checkout-modal identifier="redirectingToCheckout" />
    <now-premium-modal identifier="nowPremium" />
    <payment-success-modal identifier="paymentSuccess" />
    <payment-canceled-modal identifier="paymentCanceled" />
    <WelcomeToNewPJModal identifier="welcomeToNewPJ" />
    <WelcomeToPJ identifier="welcomeToPJ" />
    <forgot-password-modal identifier="forgotPassword" />
    <password-reset-sent-modal identifier="passwordResetSent" />
    <pay-what-you-want-modal identifier="payWhatYouWant" />
  </modal-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import ModalOverlay from "./ModalOverlay.vue";
import AccountModal from "../modals/AccountModal.vue";
import BeforeBuyingModal from "../modals/BeforeBuyingModal.vue";
import LoginModal from "../modals/LoginModal.vue";
import PrivacyPolicyModal from "../modals/PrivacyPolicyModal.vue";
import RedirectingToCheckoutModal from "../modals/RedirectingToCheckoutModal.vue";
import NowPremiumModal from "../modals/NowPremiumModal.vue";
import PaymentSuccessModal from "../modals/PaymentSuccessModal.vue";
import PaymentCanceledModal from "../modals/PaymentCanceledModal.vue";
import WelcomeToNewPJModal from "../modals/WelcomeToNewPJ.vue";
import WelcomeToPJ from "../modals/WelcomeToPJ.vue";
import ForgotPasswordModal from "../modals/ForgotPasswordModal.vue";
import PasswordResetSentModal from "../modals/PasswordResetSentModal.vue";
import PayWhatYouWantModal from "../modals/PayWhatYouWantModal.vue";

export default {
  components: {
    ModalOverlay,
    AccountModal,
    BeforeBuyingModal,
    LoginModal,
    PrivacyPolicyModal,
    RedirectingToCheckoutModal,
    NowPremiumModal,
    PaymentSuccessModal,
    PaymentCanceledModal,
    WelcomeToNewPJModal,
    WelcomeToPJ,
    ForgotPasswordModal,
    PasswordResetSentModal,
    PayWhatYouWantModal,
  },
  computed: {
    ...mapGetters(["isActiveModal", "activeModal"]),
  },
  watch: {
    isActiveModal() {
      document.body.style.overflow = this.isActiveModal ? "hidden" : "";
    },
  },
};
</script>
