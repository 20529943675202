<template>
  <modal title="Login or Sign up" ref="modalRef">
    <p class="text-white text-sm mb-6">
      Signing up allows you to add a Phasmojournal overlay to your stream.
    </p>
    <div class="flex flex-col">
      <form-validation-error :error="error" v-show="error" class="mb-4" />
      <text-area v-model="email" placeholder="Email" class="mb-2" />
      <text-area
        v-model="password"
        type="password"
        placeholder="Password"
        class="mb-4"
      />
      <p
        class="text-md text-red-500 font-semibold cursor-pointer mb-8"
        @click="forgotPassword"
      >
        Forgot your password?
      </p>
    </div>

    <div class="flex justify-between">
      <div class="">
        <Button text="Login" @click="login" class="mr-4 w-auto" />
        <Button text="Sign up" @click="signUp" />
      </div>
      <Button text="Nevermind" @click="$refs.modalRef.closeModal()" />
    </div>
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import TextArea from "../components/GenericTextArea.vue";
import Button from "../components/Button.vue";
import { auth } from "../firebase.js";
import FormValidationError from "../components/FormValidationError.vue";

export default {
  components: {
    Modal,
    Button,
    TextArea,
    FormValidationError,
  },
  data() {
    return {
      email: "",
      password: "",
      error: "",
    };
  },
  methods: {
    forgotPassword() {
      this.$refs.modalRef.closeModal();
      this.$store.commit("openModal", "forgotPassword");
    },
    login() {
      auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.email = "";
          this.password = "";
          this.error = "";
          this.$refs.modalRef.closeModal();
        })
        .catch((error) => {
          this.error = error.message;
        });
    },
    signUp() {
      auth
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.email = "";
          this.password = "";
          this.error = "";
          this.$refs.modalRef.closeModal();
        })
        .catch((error) => {
          this.error = error.message;
        });
    },
  },
};
</script>
