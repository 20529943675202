<script>
import { auth, fdb } from "../firebase.js";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      pendingMessages: [],
      started: false,
    };
  },
  mounted() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fdb
          .collection("users")
          .doc(user.uid)
          .onSnapshot((doc) => {
            this.pendingMessages = doc.data().pendingMessages;
            for (var i = 0; i < this.pendingMessages.length; i++) {
              this.$store.commit("openModal", this.pendingMessages[i]);
            }
          });
      }
      this.started = true;
      this.checkMessage();
      //TODO: set started to false and unsubscribe to event on logout
    });
  },
  methods: {
    markSeenMessage(msg) {
      auth.currentUser.getIdToken().then((token) => {
        axios.get(
          "https://us-central1-phasmojournal.cloudfunctions.net/markSeenMessage",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
            params: {
              msg: msg,
            },
          }
        );
      });
    },
    checkMessage() {
      if (this.started && this.pendingMessages.includes(this.activeModal)) {
        console.log("seen " + this.activeModal);
        this.markSeenMessage(this.activeModal);
      }
    },
  },
  watch: {
    activeModal() {
      this.checkMessage();
    },
  },
  computed: {
    ...mapGetters(["activeModal"]),
  },
};
</script>
