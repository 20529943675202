<template>
  <modal title="One more step before joining the cool kids club 😎">
    <template v-slot="{ closeModal }">
      <p class="text-white mb-6 text-sm">
        Please make sure you review our privacy policy at
        https://phasmo.lotai.xyz/privacy before buying or using this service.
        <br />
        This service is provided "as is", without warranty of any kind, express
        or implied. In no event shall the authors or copyright holders be liable
        for any claim, damages or other liability, whether in an action of
        contract, tort or otherwise, arising from, out of or in connection with
        the service or the use or other dealings in the service. <br />
        You may request a refund 7 days from the date of purchase for any
        reason. <br />
        If you would like a refund, please contact us ASAP at
        phasmojournal@lotai.xyz <br />
        We have the right to cancel, modify or discontinue the service at any
        time without any sort of reinbursement. <br />
        NOTE: Phasmojournal and the Phasmojournal stream overlay are still in
        beta. This means you might encounter issues regarding the service.
        Please report any issues you encounter to us so we can help you solve
        them. <br />
        You should recieve premium once the purchase has been completed within a
        few minutes. If you don't see premium added to your account after that
        time, please contact us ASAP and we will look into it. <br />
        By clicking the Buy button you acklowledge and agree to the above
        statements. <br />
      </p>
      <div class="flex justify-between">
        <Button text="Buy" @click="buyPremium" />
        <Button text="Close" @click="closeModal" />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import Button from "../components/Button.vue";
import { auth } from "../firebase.js";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";

let stripe;

export default {
  components: {
    Modal,
    Button,
  },
  methods: {
    getUser() {
      return auth.currentUser;
    },
    buyPremium() {
      this.$store.commit("openModal", "redirectingToCheckout");
      auth.currentUser.getIdToken().then((token) => {
        axios
          .get(
            "https://us-central1-phasmojournal.cloudfunctions.net/createPremiumPayment",
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((response) => {
            stripe.redirectToCheckout({ sessionId: response.data.id });
          });
      });
    },
  },
  async mounted() {
    stripe = await loadStripe(
      "pk_test_51J5V1YH92U6I0FEuOT8IklIjuAyxh2cS3Qcyor0XhuaaensgVFI8t6pauM4pX238M4ZlNxOKjkJgchFPZD1fiDtH00owwX01qu"
    );
  },
};
</script>
