import { createStore } from "vuex";

export default createStore({
  state: {
    openModals: [],
    accountPremium: false,
  },
  mutations: {
    openModal(state, modalName) {
      if (!state.openModals.includes(modalName)) {
        state.openModals.push(modalName);
      }
    },
    closeModal(state, modalName) {
      if (state.openModals.includes(modalName)) {
        var index = state.openModals.indexOf(modalName);
        if (index > -1) {
          state.openModals.splice(index, 1);
        }
      }
    },
    setAccountPremium(state, val) {
      if (val) {
        state.accountPremium = true;
      } else {
        state.accountPremium = false;
      }
    },
  },
  getters: {
    activeModal(state) {
      return state.openModals.length > 0 ? state.openModals.slice(-1)[0] : "";
    },
    isActiveModal(state) {
      return state.openModals.length > 0;
    },
    isModalOpen: (state) => (modalName) => {
      return state.openModals.includes(modalName);
    },
    isAccountPremium(state) {
      return state.accountPremium;
    },
  },
});
