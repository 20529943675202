<template>
  <toggle-button
    v-if="ableToConnect"
    onText="Connected"
    offText="Connect"
    :on="connected"
    :tooltip="
      connected
        ? 'This room is connected to your overlay'
        : 'Connect this room to your overlay'
    "
    @click="connect"
  />
</template>

<script>
import { auth, fdb } from "../firebase.js";
import ToggleButton from "./ToggleButton.vue";

export default {
  data() {
    return {
      connected: false,
    };
  },
  components: {
    ToggleButton,
  },
  props: {
    roomId: {
      type: String,
      default: "",
    },
  },
  methods: {
    connect() {
      if (!this.connected && this.roomId && auth.currentUser.uid) {
        console.log(auth.currentUser.uid);
        console.log(this.roomId);
        var ref = fdb.collection("overlays").doc(auth.currentUser.uid);

        ref
          .set(
            {
              roomId: this.roomId,
            },
            { merge: true }
          )
          .then(() => {
            console.log("Connected overlay");
            this.connected = true;
          })
          .catch((err) => {
            console.error(`Error connecting to overlay: ${err}`);
          });
      }
    },
  },
  computed: {
    ableToConnect() {
      return this.$store.getters.isAccountPremium && this.roomId;
    },
  },
};
</script>
