<template>
  <modal title="One more thing!" ref="modalRef">
    <p class="text-white mb-6 text-md">
      Phasmojournal is programmed, maintained and managed by a single person.
      <br />
      If you really like my work, consider supporting my work and my dreams to
      become a successful developer by entering a custom amount to pay :) <br />
      All prices are in USD, thanks :) <br />
    </p>

    <div class="flex justify-between">
      <number-input min="12" max="100" v-model="priceEntered" />
      <p>{{ priceEntered }}</p>
      <Button text="Test" @click="this.$refs.modalRef.closeModal()" />
    </div>
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import Button from "../components/Button.vue";
import NumberInput from "../components/NumberInput.vue";

export default {
  data() {
    return {
      priceEntered: 12,
    };
  },
  components: {
    Modal,
    Button,
    NumberInput,
  },
};
</script>
