<template>
  <modal title="Account" ref="modalRef">
    <p class="text-gray-400">Email</p>
    <p class="text-white text-sm mb-6">
      {{ getUser().email ? getUser().email : "" }}
    </p>
    <p class="text-gray-400">User ID</p>
    <p class="text-white text-sm mb-6">
      {{ getUser().uid ? getUser().uid : "" }}
    </p>
    <p class="text-gray-400">Premium status</p>
    <div class="flex justify-between mb-6">
      <p class="text-white text-sm">
        {{ $store.getters.isAccountPremium ? "Premium" : "Not Premium" }}
      </p>
      <!-- <Button text="Buy Premium" @click="buyPremium" v-if="!this.$store.getters.isAccountPremium" /> -->
    </div>
    <p class="text-gray-400" v-if="this.$store.getters.isAccountPremium">
      Overlay URL
    </p>
    <p
      class="text-white text-sm mb-6"
      v-if="this.$store.getters.isAccountPremium"
    >
      https://phasmo-overlay.lotai.xyz/overlay/{{ getUser().uid }}
    </p>

    <div class="flex justify-between">
      <Button text="Logout" @click="logout" />
      <Button text="Close" @click="$refs.modalRef.closeModal()" />
    </div>
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import Button from "../components/Button.vue";
import { auth } from "../firebase.js";

export default {
  components: {
    Modal,
    Button,
  },
  methods: {
    getUser() {
      return auth.currentUser;
    },
    logout() {
      auth.signOut().then(() => {
        this.$refs.modalRef.closeModal();
      });
    },
    buyPremium() {
      this.$store.commit("openModal", "payWhatYouWant");
    },
  },
};
</script>
