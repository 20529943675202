<template>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}
</style>

<script>
import { analytics } from "./firebase.js";

export default {
  mounted() {
    analytics.logEvent("page_view");
  },
};
</script>
