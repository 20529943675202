<template>
  <Popover as="div" class="relative inline-block text-left">
    <PopoverButton class="focus:border-transparent focus:outline-none ml-2">
      <font-awesome-icon :icon="['fas', 'globe-americas']" />
    </PopoverButton>
    <PopoverPanel
      class="
        absolute
        right-0
        w-52
        mt-4
        origin-top-right
        bg-gray-800
        rounded-md
        shadow-lg
      "
    >
      <div class="px-1 py-1">
        <div
          v-for="language in languages"
          :key="language.id"
          class="
            text-white
            hover:bg-red-500
            group
            flex
            rounded-md
            items-center
            w-full
            px-2
            py-1
            text-md
            font-semibold
            cursor-pointer
          "
          @click="selectLanguage(language.id)"
        >
          {{ language.name }}
        </div>
      </div>
    </PopoverPanel>
  </Popover>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { analytics } from "../firebase.js";

export default {
  components: {
    Popover,
    PopoverPanel,
    PopoverButton,
  },
  data() {
    return {
      languages: [
        { name: "🇺🇸 English", id: "en" },
        { name: "🇷🇺 Русский", id: "ru" },
      ],
    };
  },
  methods: {
    selectLanguage(language) {
      this.$i18n.locale = language;
      analytics.setUserProperties({ language: language });
      analytics.logEvent("select_language", { language: language });
    },
  },
};
</script>
