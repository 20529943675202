<template>
  <nav
    class="flex items-center justify-between flex-wrap bg-teal-500 p-6 relative"
  >
    <div>
      <nav-button
        :text="createRoomText"
        :selected="isInRoom"
        @click="createRoom"
      />
      <sync-room-to-overlay-btn :roomId="roomId" />
    </div>
    <div class="title">
      <div class="flex items-center">
        <h1 class="text-3xl font-semibold">{{ $t("phasmojournal") }}</h1>
        <h1 class="text-sm font-semibold bg-red-500 rounded-sm px-1 ml-2">
          BETA
        </h1>
      </div>
    </div>
    <div>
      <nav-button :text="$t('game')" :selected="true" />
      <nav-button :text="$t('questions')" tooltip="Coming soon™" />
      <nav-button :text="$t('maps')" tooltip="Coming soon™" />
      <a
        href="https://phasmo.lotai.xyz/discord"
        class="focus:border-transparent focus:outline-none ml-2"
        target="_blank"
        rel="noopener noreferrer"
      >
        <font-awesome-icon :icon="['fab', 'discord']" />
      </a>
      <select-language />
      <extra-menu />
    </div>
  </nav>
</template>

<style scoped>
.title {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
</style>

<script>
import NavButton from "./NavButton.vue";
import SelectLanguage from "./SelectLanguage.vue";
import ExtraMenu from "./ExtraMenu.vue";
import SyncRoomToOverlayBtn from "./SyncRoomToOverlayBtn.vue";

export default {
  components: {
    NavButton,
    SelectLanguage,
    ExtraMenu,
    SyncRoomToOverlayBtn,
  },
  methods: {
    createRoom() {
      this.$emit("createRoomClicked");
    },
  },
  props: {
    roomId: {
      type: String,
    },
    syncing: {
      type: Boolean,
    },
  },
  computed: {
    createRoomText() {
      return this.roomId ? this.$t("copyLink") : this.$t("createRoom");
    },
    isInRoom() {
      return this.roomId != "";
    },
  },
};
</script>
