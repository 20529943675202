<template>
  <modal title="Payment Cancelled 😟" ref="modalRef">
    <p>
      You're payment was cancelled :( <br />
      If you had any problems with payment or have any questions, please email
      us at phasmojournal@lotai.xyz <br />
      Your card has not been charged. <br />
    </p>
    <div class="flex justify-end">
      <Button text="Okay" @click="this.$refs.modalRef.closeModal()" />
    </div>
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import Button from "../components/Button.vue";

export default {
  components: {
    Modal,
    Button,
  },
};
</script>
