<template>
  <modal title="Welcome to the cool kids club 😎" ref="modalRef">
    <p>
      LET'S FUCKING GOOOO, you are now premium! 🎉 <br />
      <br />
      Check out your new superpowers by clicking the
      <span class="text-red-500 font-semibold">Connect</span> button in the top
      left to connect your room to the stream overlay! <br />
      Also check out your extra settings and info on setting up your sick new
      overlay that everyone is probably gonna love. <br />
      You can also share the room link with your twitch mods or helpers and they
      can update the overlay for you, or use a static password-protected link
      (coming soon™) <br />
      You may have to refresh the page to see the changes and benefits you now
      get. <br />
      Hope you enjoy and thank you so much for supporting Phasmojournal! <br />
      If you have any questions, feel free to message me on Discord or email us
      at phasmojournal@lotai.xyz <br />
      <br />
    </p>
    <text-with-badge text="- sexnine" badgeText="Creator of Phasmojournal" />
    <div class="flex justify-end">
      <Button text="Fuck yeah!" @click="this.$refs.modalRef.closeModal()" />
    </div>
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import Button from "../components/Button.vue";
import TextWithBadge from "../components/TextWithBadge.vue";

export default {
  components: {
    Modal,
    Button,
    TextWithBadge,
  },
};
</script>
