<template>
  <div class="sticky-header z-10 pb-0.5 shadow-md">
    <header-bar @create-room-clicked="createRoom" :roomId="roomId" />
    <div class="flex justify-center mb-8 flex-col sm:flex-row gap-y-2">
      <div class="flex-1 flex">
        <toggle-button
          class="ml-auto"
          :onText="$t('reset')"
          @click="reset"
          :on="canBeReset"
        />
      </div>
      <input
        v-model="ghostName"
        type="text"
        :placeholder="$t('ghostName')"
        maxlength="50"
        class="
          justify-self-center
          bg-gray-800
          text-white
          rounded-lg
          shadow-md
          text-lg
          pr-2
          py-2
          pl-4
          transform
          ease-in-out
          duration-150
          focus:scale-105
          focus:ring-2
          focus:ring-red-500
          focus:border-transparent
          focus:outline-none
        "
      />
      <div class="flex-1 flex">
        <toggle-button
          class=""
          :onText="$t('answersEveryone')"
          :offText="$t('answersAlone')"
          :on="answersEveryone"
          offClasses="bg-red-500"
          @click="toggleAnswersEveryone"
        />
      </div>
    </div>
    <div class="flex justify-center items-center mb-4 flex-wrap gap-y-2">
      <p class="text-lg font-semibold mr-4 xl:mr-12">{{ $t("evidence") }}</p>
      <value-toggle-button
        v-for="evidence in evidenceList"
        :key="evidence"
        :state="getEvidenceState(evidence)"
        @click="toggleEvidence(evidence)"
        :onText="$t(evidence)"
        :shrink="true"
      />
    </div>
    <div class="flex justify-center items-center mb-8 flex-wrap gap-y-2">
      <p class="text-lg font-semibold mr-4 xl:mr-12">{{ $t("objectives") }}</p>
      <value-toggle-button
        v-for="objective in objectivesList"
        :key="objective"
        :state="getObjectiveState(objective)"
        @click="toggleObjective(objective)"
        :tooltip="$t('objectivesList.' + objective + '.fullText')"
        tooltipDelay="1s"
        :onText="$t('objectivesList.' + objective + '.name')"
        :shrink="true"
      />
    </div>
    <modals-root />
    <modal-router-handler :url="url" />
    <messages-handler />
    <debug-menu v-if="false" />
  </div>
  <div
    class="flex flex-wrap content-start justify-center gap-5 px-10 pt-4 mb-8"
  >
    <ghost-card
      v-for="ghost in ghostsData"
      :key="ghost.name"
      :data="ghost"
      :foundEvidence="foundEvidence"
      v-show="shouldShowGhost(ghost)"
    />
  </div>
</template>

<style scoped>
.sticky-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background-color: #1e2630;
}
.centered {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
</style>

<script>
import HeaderBar from "../components/HeaderBar.vue";
import ToggleButton from "../components/ToggleButton.vue";
import GhostCard from "../components/GhostCard.vue";
import ghostsDataJson from "../assets/ghost_data.json";
import { generateRoomId } from "../genRoomId.js";
import { db, analytics, auth, fdb } from "../firebase.js";
import ModalsRoot from "../components/ModalsRoot.vue";
import ModalRouterHandler from "../components/ModalRouterHandler.vue";
import DebugMenu from "../components/DebugMenu.vue";
import MessagesHandler from "../components/MessagesHandler.vue";
import ValueToggleButton from "../components/ValueToggleButton.vue";

var dbRef;
var syncDebounceTimer;
var ghostNameSyncDebounceTimer;

export default {
  data() {
    return {
      ghostName: "",
      evidence: {},
      objectives: {},
      answersEveryone: true,
      evidenceToggleTimeoutsList: [],
      evidenceList: [
        "emf",
        "fingerprints",
        "ghostOrb",
        "spiritBox",
        "temperature",
        "writing",
        "dots",
      ],
      objectivesList: [
        "crucifix",
        "event",
        "motion",
        "photo",
        "emf",
        "smudge",
        "candle",
        "salt",
        "sanity",
        "hunt",
        "repel",
        "parabolic",
      ],
      ghostsData: ghostsDataJson,
      roomId: "",
      syncing: false,
    };
  },
  methods: {
    isFoundEvidence(e) {
      return this.foundEvidence.includes(e);
    },
    toggleAnswersEveryone() {
      this.answersEveryone = !this.answersEveryone;
      this.syncToDb();
    },
    toggleEvidence(e) {
      const currentState = this.evidence[e];
      if (currentState == "yes") {
        if (this.evidenceToggleTimeoutsList.indexOf(e) > -1) {
          this.evidence[e] = "ruledOut";
          this.syncToDb();
        } else {
          this.evidence[e] = "no";
          this.syncToDb();
        }
      } else if (currentState == "ruledOut") {
        this.evidence[e] = "no";
        this.syncToDb();
      } else {
        if (this.matchingEvidence.includes(e)) {
          this.evidence[e] = "yes";
          this.syncToDb();
          if (this.evidenceToggleTimeoutsList.indexOf(e) == -1) {
            this.evidenceToggleTimeoutsList.push(e);
            setTimeout(() => {
              var index = this.evidenceToggleTimeoutsList.indexOf(e);
              if (index > -1) {
                this.evidenceToggleTimeoutsList.splice(index, 1);
              }
            }, 400);
          }
        }
      }
    },
    getEvidenceState(e) {
      return !this.matchingEvidence.includes(e) &&
        this.evidence[e] != "ruledOut"
        ? "disabled"
        : this.evidence[e];
    },
    getObjectiveState(o) {
      return this.objectives[o] == "no" && this.selectedObjectives.length >= 3
        ? "disabled"
        : this.objectives[o];
    },
    toggleObjective(o) {
      const currentState = this.objectives[o];
      if (currentState == "yes") {
        this.objectives[o] = "ruledOut";
        this.syncToDb();
      } else if (currentState == "ruledOut") {
        this.objectives[o] = "no";
        this.syncToDb();
      } else if (currentState == "no") {
        if (this.selectedObjectives.length < 3) {
          this.objectives[o] = "yes";
          this.syncToDb();
        }
      }
    },
    shouldShowGhost(ghost) {
      return this.matchingGhosts.includes(ghost);
    },
    shouldEvidenceBeDisabled(evidence) {
      return !this.matchingEvidence.includes(evidence);
    },
    shouldObjectiveBeDisabled(objective) {
      return (
        this.selectedObjectives.length >= 3 &&
        !this.selectedObjectives.includes(objective)
      );
    },
    reset() {
      this.ghostName = "";
      this.evidenceList.forEach((e) => {
        this.evidence[e] = "no";
      });
      this.objectivesList.forEach((o) => {
        this.objectives[o] = "no";
      });
      this.answersEveryone = true;
      this.syncToDb();
    },
    pushToDb() {
      console.log("Syncing data to database");
      dbRef.update({
        ghostName: this.ghostName,
        answersEveryone: this.answersEveryone,
        evidence: this.evidence,
        objectives: this.objectives,
      });
      this.setToNotSyncing();
      analytics.logEvent("push_to_db", { room_id: this.roomId });
    },
    syncToDb() {
      if (dbRef) {
        this.setToSyncing();
        clearTimeout(syncDebounceTimer);
        syncDebounceTimer = setTimeout(() => {
          this.pushToDb();
        }, 300);
      }
    },
    createRoom() {
      if (!this.roomId) {
        this.setupRoom();
        this.pushToDb();
        analytics.logEvent("create_room", { room_id: this.roomId });
      } else {
        this.$copyText("https://phasmo.lotai.xyz/room/" + this.roomId);
      }
    },
    setupRoom() {
      console.log("Setting up room");
      this.roomId = this.enteredRoomId ? this.enteredRoomId : generateRoomId();
      // console.log("db: " + db);
      dbRef = db.ref("rooms/" + this.roomId);
      // console.log("dbRef: " + dbRef);
      this.listenToDbUpdates();
      this.$router.push("/room/" + this.roomId);
    },
    setToSyncing() {
      this.syncing = true;
    },
    setToNotSyncing() {
      this.syncing = false;
    },
    listenToDbUpdates() {
      console.log("Listening to database updates");
      dbRef.on(
        "value",
        (snapshot) => {
          var value = snapshot.val();
          console.log(value);
          if ("ghostName" in value) {
            this.ghostName = value.ghostName;
          }
          if ("answersEveryone" in value) {
            this.answersEveryone = value.answersEveryone;
          }
          if ("evidence" in value) {
            this.evidence = value.evidence;
          }
          if ("objectives" in value) {
            this.objectives = value.objectives;
          }
        },
        (error) => {
          if (error) {
            console.log("Firebase on error: " + error);
          }
        }
      );
    },
  },
  computed: {
    matchingGhosts() {
      var res = [];
      this.ghostsData.forEach((ghost) => {
        for (var evidence in this.evidence) {
          const state = this.evidence[evidence];
          if (state == "yes") {
            if (!ghost.evidence.includes(evidence)) {
              return;
            }
          } else if (state == "ruledOut") {
            if (ghost.evidence.includes(evidence)) {
              return;
            }
          }
        }
        res.push(ghost);
      });
      return res;
    },
    matchingEvidence() {
      var res = [];
      for (var i = 0; i < this.matchingGhosts.length; i++) {
        this.matchingGhosts[i].evidence.forEach((val) => {
          if (!res.includes(val)) {
            res.push(val);
          }
        });
      }

      return res;
    },
    foundEvidence() {
      var res = [];
      for (var evidence in this.evidence) {
        if (this.evidence[evidence] == "yes") {
          res.push(evidence);
        }
      }
      return res;
    },
    selectedObjectives() {
      var res = [];
      for (var objective in this.objectives) {
        if (this.objectives[objective] != "no") {
          res.push(objective);
        }
      }
      return res;
    },
    canBeReset() {
      var ret = this.ghostName !== "" || !this.answersEveryone;
      if (!ret) {
        for (var evidence in this.evidence) {
          if (this.evidence[evidence] != "no") {
            return true;
          }
        }
        for (var objective in this.objectives) {
          if (this.objectives[objective] != "no") {
            return true;
          }
        }
      }
      return ret;
    },
  },
  props: {
    enteredRoomId: {
      type: String,
    },
    url: {
      type: String,
      default: "",
    },
  },
  mounted() {
    // if (!localStorage.getItem("seenNewPJModal")) {
    //   this.$store.commit("openModal", "welcomeToNewPJ");
    //   setTimeout(() => {
    //     localStorage.setItem("seenNewPJModal", true);
    //   }, 15000);
    // }
    this.reset();
    if (this.enteredRoomId) {
      console.log("User put in link to room");
      this.setupRoom();
      analytics.logEvent("join_room", { room_id: this.roomId });
    }
    auth.onAuthStateChanged((user) => {
      if (user) {
        fdb
          .collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.data().premium) {
              this.$store.commit("setAccountPremium", true);
            }
          });
      } else {
        this.$store.commit("setAccountPremium", false);
      }
    });
  },
  watch: {
    ghostName() {
      if (dbRef) {
        this.setToSyncing();
        clearTimeout(ghostNameSyncDebounceTimer);
        ghostNameSyncDebounceTimer = setTimeout(() => {
          this.pushToDb();
        }, 1000);
      }
    },
  },
  components: {
    HeaderBar,
    ToggleButton,
    GhostCard,
    ModalsRoot,
    ModalRouterHandler,
    DebugMenu,
    MessagesHandler,
    ValueToggleButton,
  },
};
</script>
