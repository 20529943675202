import firebase from "firebase/app";
import "firebase/database";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAlsWQ7QIccwXPEXT-ho30HDii3BtkbQRM",
  authDomain: "phasmojournal.firebaseapp.com",
  databaseURL: "https://phasmojournal-default-rtdb.firebaseio.com",
  projectId: "phasmojournal",
  storageBucket: "phasmojournal.appspot.com",
  messagingSenderId: "695907430683",
  appId: "1:695907430683:web:b8df56cb851f868d62a3be",
  measurementId: "G-P0032CKB1M",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.database();
const analytics = firebase.analytics();
const auth = firebase.auth();
const fdb = firebase.firestore();

// firebase.setLogLevel("debug");

export { analytics, db, auth, fdb };
