<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      modalUrls: {
        privacy: "/privacy",
        paymentSuccess: "/paymentSuccess",
        paymentCanceled: "/paymentCanceled",
      },
    };
  },
  computed: {
    ...mapGetters(["isActiveModal", "activeModal"]),
  },
  watch: {
    activeModal() {
      if (this.activeModal in this.modalUrls) {
        // this.$router.push(this.modalUrls[this.activeModal]);
      } else {
        // this.$router.push("/");
      }
    },
  },
  mounted() {
    if (this.url) {
      if (Object.values(this.modalUrls).indexOf("/" + this.url) > -1) {
        this.$store.commit("openModal", this.url);
      } else {
        this.$router.push("/");
      }
    }
  },
  props: {
    url: {
      type: String,
      default: "",
    },
  },
};
</script>
