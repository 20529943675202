<template>
  <div
    :class="classes"
    class="
      whitespace-nowrap
      inline-flex
      items-center
      justify-center
      px-4
      py-2
      rounded-lg
      shadow-sm
      text-base text-gray-200
      font-semibold
      cursor-pointer
      focus:border-transparent focus:outline-none
      tooltipbtn
    "
    :style="{
      '--delay': tooltipDelay,
      '--tooltipVisible': tooltip === '' ? 'hidden' : 'visible',
    }"
  >
    {{ text }}
    <span
      :v-if="tooltip"
      class="tooltip text-sm bg-opacity-60 bg-black text-center rounded-lg"
      >{{ tooltip }}</span
    >
  </div>
</template>

<style scoped>
.tooltipbtn .tooltip {
  visibility: hidden;
  padding: 5px 10px;
  width: auto;
  position: absolute;
  z-index: 5;
  transition: 0s visibility;

  top: 90%;
  left: 43.5%;
  margin-left: -60px;
}

.tooltipbtn:hover .tooltip {
  visibility: var(--tooltipVisible);
  transition-delay: var(--delay);
}
</style>

<script>
export default {
  props: {
    onText: {
      type: String,
      required: true,
    },
    offText: {
      type: String,
      required: false,
      default: null,
    },
    onClasses: {
      type: String,
      default: "bg-red-500 hover:text-white",
    },
    offClasses: {
      type: String,
      default: "bg-red-500 bg-opacity-50 hover:text-white",
    },
    on: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledClasses: {
      type: String,
      default: "bg-gray-500 bg-opacity-40",
    },
    tooltip: {
      type: String,
      default: "",
    },
    tooltipDelay: {
      type: String,
      default: ".5s",
    },
    shrink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      var res = "";
      if (this.disabled) {
        res = this.disabledClasses;
      } else if (this.on) {
        res = this.onClasses;
      } else {
        res = this.offClasses;
      }
      if (this.shrink) {
        res += " mx-2 xl:mx-4";
      } else {
        res += " mx-4";
      }

      return res;
    },
    text() {
      if (this.offText == null) {
        return this.onText;
      } else if (this.on) {
        return this.onText;
      } else {
        return this.offText;
      }
    },
  },
};
</script>
