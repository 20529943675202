<template>
  <modal title="Payment Completed ✅" ref="modalRef">
    <p>
      Your payment was successful. <br />
      You should have a notification pop up soon confirming that you're account
      has been upgraded to premium. <br />
      Otherwise, check your account details in the menu in the top right to
      confirm your premium status, <br />
      Try refreshing the page if you do not see it there. <br />
      If you have any problems or you don't get your premium status within the
      next few minutes, don't worry! There could be an issue with our servers or
      there was a problem with the transaction. <br />
      Please contact us ASAP at phasmojournal@lotai.xyz if you have any problems
      and we will be happy to help :)
    </p>
    <div class="flex justify-end">
      <Button text="Okay" @click="this.$refs.modalRef.closeModal()" />
    </div>
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import Button from "../components/Button.vue";

export default {
  components: {
    Modal,
    Button,
  },
};
</script>
