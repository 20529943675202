<template>
  <modal title="👋 Welcome to Phasmojournal!" ref="modalRef">
    <p class="text-white mb-6 text-md">
      Hey there, <br />
      Thanks for signing up to Phasmojournal! <br />
      You can purchase premium to get a sick stream overlay for your Twitch or
      Youtube channel under your account settings. <br />
      If you have any questions, feel free to hit me up on
      <a class="font-bold text-red-500" href="https://phasmo.lotai.xyz/discord"
        >Discord</a
      >
      ;)
    </p>
    <text-with-badge text="- sexnine" badgeText="Creator of Phasmojournal" />
    <div class="flex justify-end">
      <Button text="Sweeeeet" @click="this.$refs.modalRef.closeModal()" />
    </div>
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import Button from "../components/Button.vue";
import TextWithBadge from "../components/TextWithBadge.vue";

export default {
  components: {
    Modal,
    Button,
    TextWithBadge,
  },
};
</script>
