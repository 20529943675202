<template>
  <Popover as="div" class="relative inline-block text-left">
    <PopoverButton class="focus:border-transparent focus:outline-none ml-2">
      <font-awesome-icon :icon="['fas', 'bars']" />
    </PopoverButton>
    <PopoverPanel
      class="
        absolute
        right-0
        w-52
        mt-4
        origin-top-right
        bg-gray-800
        rounded-md
        shadow-lg
      "
    >
      <div class="px-1 py-1">
        <div
          class="
            text-white
            hover:bg-red-500
            group
            flex
            rounded-md
            items-center
            w-full
            px-2
            py-1
            text-md
            font-semibold
            cursor-pointer
          "
          @click="
            getUser()
              ? $store.commit('openModal', 'account')
              : $store.commit('openModal', 'login')
          "
        >
          {{ getUser() ? "Account" : "Login" }}
        </div>
        <div
          class="
            text-white
            hover:bg-red-500
            group
            flex
            rounded-md
            items-center
            w-full
            px-2
            py-1
            text-md
            font-semibold
            cursor-pointer
          "
          @click="$store.commit('openModal', 'privacy')"
        >
          Privacy
        </div>
      </div>
    </PopoverPanel>
  </Popover>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { auth } from "../firebase.js";

export default {
  components: {
    Popover,
    PopoverPanel,
    PopoverButton,
  },
  methods: {
    getUser() {
      return auth.currentUser;
    },
  },
};
</script>
