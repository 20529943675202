<template>
  <div
    class="
      mx-4
      whitespace-nowrap
      inline-flex
      items-center
      justify-center
      px-4
      py-2
      rounded-lg
      shadow-sm
      text-base text-gray-200
      bg-red-500
      hover:text-white hover:shadow-lg hover:scale-105
      transform
      ease-in-out
      font-semibold
      duration-150
      cursor-pointer
      tooltipbtn
    "
    :class="opacity"
    :style="{
      '--delay': tooltipDelay,
      '--tooltipVisible': tooltip === '' ? 'hidden' : 'visible',
    }"
  >
    {{ text }}
    <span
      :v-if="tooltip"
      class="tooltip text-sm bg-opacity-40 bg-black text-center rounded-lg"
      >{{ tooltip }}</span
    >
  </div>
</template>

<style scoped>
.tooltipbtn .tooltip {
  visibility: hidden;
  padding: 5px 10px;
  width: auto;
  position: absolute;
  z-index: 1;
  transition: 0s visibility;

  top: 120%;
  left: 50%;
  margin-left: -60px;
}

.tooltipbtn:hover .tooltip {
  visibility: var(--tooltipVisible);
  transition-delay: var(--delay);
}
</style>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "",
    },
    tooltipDelay: {
      type: String,
      default: ".5s",
    },
  },
  computed: {
    opacity() {
      if (this.selected == true) {
        return "";
      } else {
        return "bg-opacity-50";
      }
    },
  },
};
</script>
